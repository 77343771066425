import styled from "styled-components";

type StyledProps = {};

export default styled.div<StyledProps>`
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 50px;
  h3 {
    margin: 0 0 10px 0;
    font-weight: bold;
    font-size: 20px;
  }
  ul {
    margin: 0;
    padding: 0;
    margin: 0 0 30px 0;
  }
  li {
    list-style: none;
    margin: 0 0 18px 0;
    a {
      text-decoration: underline;
      font-weight: normal;
      color: #2638c4;
      &:hover,
      &:active,
      &:visited {
        color: #0f1e8c;
      }
    }
    .dateTime {
      font-size: 14px;
    }
  }
  button {
    border: 0;
    font-size: 20px;
    padding: 10px 18px;
    background-color: #2638c4;
    color: #fff;
    border-radius: 5px;
  }
`;
