// get the value of the 'sites' query string parameter or default to 'site_68FBe1c2dE'
const sites = [
  {
    id: "site_21cc4D5A1F",
    name: "Black Hills Studio",
    url: "https://www.sd.net/blackhills/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_21cc4D5A1F/live.m3u8",
  },
  {
    id: "site_189711eF82",
    name: "DANR - Matthew Training Center – Foss Building",
    url: "https://www.sd.net/mtc/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_189711eF82/live.m3u8",
  },
  {
    id: "site_9DDDA029a6",
    name: "DOE - MacKay Building - Library Commons",
    url: "https://www.sd.net/mackay/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_9DDDA029a6/live.m3u8",
  },
  {
    id: "site_570114aCDc",
    name: "DOT - Becker-Hansen Building – Commission Room ",
    url: "https://www.sd.net/bhb/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_570114aCDc/live.m3u8",
  },
  {
    id: "site_51f624C077",
    name: "Governor's Large Conference Room",
    url: "https://www.sd.net/glcr/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_51f624C077/live.m3u8",
  },
  {
    id: "site_31003186F7",
    name: "House",
    url: "https://www.sd.net/house-video/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_31003186F7/live.m3u8",
  },
  {
    id: "site_5d5b8aE5aB",
    name: "Remote 1",
    url: "https://www.sd.net/remote1/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_5d5b8aE5aB/live.m3u8",
  },
  {
    id: "site_d1FFAE6D1C",
    name: "Remote 2",
    url: "https://www.sd.net/remote2/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_d1FFAE6D1C/live.m3u8",
  },
  {
    id: "site_812a749a66",
    name: "Remote 3",
    url: "https://www.sd.net/remote3/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_812a749a66/live.m3u8",
  },
  {
    id: "site_F60cBF3C7B",
    name: "Room 362 (LCR 1)",
    url: "https://www.sd.net/room362/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_F60cBF3C7B/live.m3u8",
  },
  {
    id: "site_D18Bb8585B",
    name: "Room 412",
    url: "https://www.sd.net/room412/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_D18Bb8585B/live.m3u8",
  },
  {
    id: "site_1939D49031",
    name: "Room 413",
    url: "https://www.sd.net/room413/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_1939D49031/live.m3u8",
  },
  {
    id: "site_697402642d",
    name: "Room 414",
    url: "https://www.sd.net/room414/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_697402642d/live.m3u8",
  },
  {
    id: "site_C3871AA6F8",
    name: "Room 423",
    url: "https://www.sd.net/room423/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_C3871AA6F8/live.m3u8",
  },
  {
    id: "site_b9BDB0D641",
    name: "Room 464",
    url: "https://www.sd.net/room464/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_b9BDB0D641/live.m3u8",
  },
  {
    id: "site_C3D89FE86B",
    name: "SDPB-1",
    url: "https://www.sdpb.org/live/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_C3D89FE86B/live.m3u8",
  },
  {
    id: "site_84DCECD6e9",
    name: "SDPB-2",
    url: "https://www.sdpb.org/live/2/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_84DCECD6e9/live.m3u8",
  },
  {
    id: "site_285581EBEE",
    name: "SDPB-3",
    url: "https://www.sdpb.org/live/3/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_285581EBEE/live.m3u8",
  },
  {
    id: "site_7053600E40",
    name: "SDPB-4",
    url: "https://www.sdpb.org/live/4/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_7053600E40/live.m3u8",
  },
  {
    id: "site_D4A65ab993",
    name: "SDPB-5",
    url: "https://www.sdpb.org/live/5/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_D4A65ab993/live.m3u8",
  },
  {
    id: "site_5E02C113E7",
    name: "SDPB-6",
    url: "https://www.sdpb.org/live/6/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_5E02C113E7/live.m3u8",
  },
  {
    id: "site_99A12A8770",
    name: "Senate",
    url: "https://www.sd.net/senate-video/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_99A12A8770/live.m3u8",
  },
  {
    id: "site_b38CB4C9B4",
    name: "Sioux Falls Studio",
    url: "https://www.sd.net/siouxfalls/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_b38CB4C9B4/live.m3u8",
  },
  {
    id: "site_66B8743BA7",
    name: "Test Makito Stream",
    url: "https://www.sd.net/sardius/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_66B8743BA7/live.m3u8",
  },
  {
    id: "site_2d90D51347",
    name: "Unified Judicial System",
    url: "https://www.sd.net/ujs/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_2d90D51347/live.m3u8",
  },
  {
    id: "site_42A1BE81Fa",
    name: "UJS - Court 2",
    url: "https://www.sd.net/ujs/Court2/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_42A1BE81Fa/live.m3u8",
  },
  {
    id: "site_55CA130f1F",
    name: "UJS - Court 3",
    url: "https://www.sd.net/ujs/Court3/",
    stream:
      "https://watch.sardius.media/channels/69A28AFcE4D9711/site_55CA130f1F/live.m3u8",
  },
];
export default sites;
