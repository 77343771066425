import React from "react";

import {
  ScheduleParams,
  ScheduleEvent,
  ScheduleItems,
  SitesType,
} from "./Schedule.type";
import ScheduleStyled from "./Schedule.styled";
import sites from "./sites";

const Schedule: React.FC<ScheduleParams> = ({
  showMore = false,
  limit = 15,
  ver = "1",
  channels = [],
  testing = false,
  categories = [],
}) => {
  const [calendars, setCalendars] = React.useState<ScheduleItems[]>([]);
  const [allSites, setAllSites] = React.useState<SitesType>([]);
  const [liveEvents, setLiveEvents] = React.useState<ScheduleItems>([]);
  const [futureEvents, setFutureEvents] = React.useState<ScheduleItems>([]);
  const [showAll, setShowAll] = React.useState<boolean>(false);
  function getDateAfterDays(days: number) {
    const today = new Date();
    const futureDate = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
    const year = futureDate.getFullYear();
    const month = ("0" + (futureDate.getMonth() + 1)).slice(-2);
    const day = ("0" + futureDate.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }
  function constructApiUrl(
    sites: string,
    startDays = 0,
    endDays = 30,
    experience = "access_default"
  ) {
    const start = getDateAfterDays(startDays) + "T00:00:00Z";
    const end = getDateAfterDays(endDays) + "T23:59:59Z";

    return `https://api.sardius.media/calendars/69A28AFcE4D9711/${sites}/sites?start=${start}&end=${end}&experience=${experience}ver=${ver}`;
  }
  React.useEffect(() => {
    setAllSites(
      channels?.length > 0
        ? sites.filter((site) => channels.includes(site.id))
        : sites
    );
  }, [channels]);

  const findOne = (haystack: string[], arr: string[]) => {
    return arr.some((v) => haystack.includes(v));
  };

  React.useEffect(() => {
    if (allSites.length > 0) {
      const apiUrls = allSites.map((site) => constructApiUrl(site.id));
      // map each URL to a fetch promise
      const promises = apiUrls.map((url) =>
        fetch(url).then((response) => response.json())
      );

      // wait for all promises to resolve
      Promise.all<any[]>(promises)
        .then((data) => {
          const newCalendars = data.map(
            (result: ScheduleItems, resultIndex) => {
              return result
                .filter((event) =>
                  categories.length > 0
                    ? findOne(
                        categories,
                        event.metadata?.asset?.categories || []
                      )
                    : true
                )
                .map((item: ScheduleEvent) => {
                  item.link = allSites[resultIndex].url;
                  if (testing) {
                    return item;
                  } else {
                    const isTesting =
                      !!item?.metadata?.asset?.categories?.includes("testing");
                    return isTesting ? { id: "", start: "", end: "" } : item;
                  }
                });
            }
          );
          setCalendars(newCalendars);
        })
        .catch((error) => console.error(error));
    } // log any errors to the console
  }, [allSites]);

  React.useEffect(() => {
    const empty: ScheduleItems = [];
    const allEvents = empty.concat.apply([], calendars);
    const currentEvents = allEvents
      .filter((item) => {
        return new Date(item.end) > new Date();
      })
      .sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.start).getTime() - new Date(b.start).getTime();
      });
    setLiveEvents(
      currentEvents.filter(
        (item) =>
          new Date().getTime() > new Date(item.start).getTime() &&
          new Date().getTime() < new Date(item.end).getTime()
      )
    );
    setFutureEvents(
      currentEvents.filter(
        (item) => new Date().getTime() < new Date(item.start).getTime()
      )
    );
  }, [calendars]);
  const getUpperLimit = () => (showAll ? futureEvents.length : limit);
  return (
    <ScheduleStyled>
      {liveEvents.length > 0 && (
        <div>
          <h3>Live Now</h3>
          <ul>
            {liveEvents.map((item, itemIndex) => {
              const pageUrl =
                item?.metadata?.asset?.metadata?.pageUrl || item.link;
              // format the start and end date in MM/DD/YYYY h:mm a format lkjlj
              const startDate = new Date(item.start).toLocaleString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });
              const endDate = new Date(item.end).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });

              // create the list item with the formatted data
              return (
                <li key={item.id}>
                  <b>
                    <a href={pageUrl}>{item.title}</a>
                  </b>
                  <div className="dateTime">
                    ({startDate} - {endDate})
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <h3>Upcoming Events</h3>
      <ul>
        {futureEvents.slice(0, getUpperLimit()).map((item, itemIndex) => {
          const pageUrl = item?.metadata?.asset?.metadata?.pageUrl || item.link;
          // format the start and end date in MM/DD/YYYY h:mm a format
          const startDate = new Date(item.start).toLocaleString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          const endDate = new Date(item.end).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });

          // create the list item with the formatted data
          return (
            <li key={item.id}>
              <b>
                <a href={pageUrl}>{item.title}</a>
              </b>
              <div className="dateTime">
                ({startDate} - {endDate})
              </div>
            </li>
          );
        })}
      </ul>

      {!showMore && futureEvents.length > limit && (
        <button
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {showAll ? "Hide" : "Show More"}
        </button>
      )}
    </ScheduleStyled>
  );
};

Schedule.displayName = "Schedule";
export default Schedule;
