import React from "react";
import ReactDOM from "react-dom/client";
import Schedule from "./Schedule";
import reportWebVitals from "./reportWebVitals";

const el = document.getElementById("sm-sdpb-schedule") as HTMLElement;
const root = ReactDOM.createRoot(el);
root.render(
  <React.StrictMode>
    <Schedule
      limit={
        el.getAttribute("data-limit")
          ? Number(el.getAttribute("data-limit"))
          : 15
      }
      ver={el.getAttribute("data-ver") || "1"}
      channels={
        el.getAttribute("data-channels")
          ? el.getAttribute("data-channels")?.split(",")
          : undefined
      }
      testing={el.getAttribute("data-testing") ? true : false}
      categories={
        el.getAttribute("data-categories")
          ? el.getAttribute("data-categories")?.split(",")
          : undefined
      }
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
